import '../styles/user.scss';

import IMask from 'imask';

import jQuery from 'jquery';
const $ = require('jquery');
global.$ = global.jQuery = $;
import 'jquery-ui';

const bootstrap = require('bootstrap');

// import './search';

// import 'bootstrap4-toggle/js/bootstrap4-toggle.min.js';

// import fontawesome from "@fortawesome/fontawesome-free/scss/fontawesome.scss";
// import regular from "@fortawesome/fontawesome-free/scss/regular.scss";
// import solid from "@fortawesome/fontawesome-free/scss/solid.scss";
// import brands from "@fortawesome/fontawesome-free/scss/brands.scss";

// loads the code syntax highlighting library
// import './highlight.js';

// import hljs from 'highlight.js/lib/core';

// ['javascript', 'twig', 'sql', 'php'].forEach((langName) => {
//   // Using require() here because import() support hasn't landed in Webpack yet
//   const langModule = require(`highlight.js/lib/languages/${langName}`);
//   hljs.registerLanguage(langName, langModule);
// });



// const hljs = require('highlight.js/lib/core');  // require only the core library
// // separately require languages
// hljs.registerLanguage('xml', require('highlight.js/lib/languages/xml'));
// hljs.registerLanguage('javascript', require('highlight.js/lib/languages/javascript'));
// hljs.registerLanguage('twig', require('highlight.js/lib/languages/twig'));
// hljs.registerLanguage('sql', require('highlight.js/lib/languages/sql'));
// hljs.registerLanguage('php', require('highlight.js/lib/languages/php'));

// const highlightedCode = hljs.highlight('<span>Hello World!</span>', {language: 'xml'}).value

// import hljs from 'highlight.js/lib/core';
// import javascript from 'highlight.js/lib/languages/javascript';
// hljs.registerLanguage('javascript', javascript);

// Creates links to the Symfony documentation
// import './doclinks.js';

import 'slider-fotorama/dist/fotorama';
// import 'select2/dist/js/select2.min.js';

// import 'bs-custom-file-input/dist/bs-custom-file-input';
// import bsCustomFileInput from 'bs-custom-file-input';

// window.bsCustomFileInput = bsCustomFileInput;

// import MeasureTool from 'measuretool-googlemaps-v3';
// window.MeasureTool = MeasureTool;
